* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-1c1c1b: #1c1c1b;
  --color-777777: #777777;
}

.errors {
  color: red !important;
  font-size: 12px !important;
}

.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

body {
  font-family: 'Rubik', sans-serif;
}

input[type='checkbox'] {
  margin-top: 5px;
}

a {
  text-decoration: none;
  display: inline-block;
}

p {
  margin-bottom: 0px;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-30 {
  gap: 30px;
}

.gap-60 {
  gap: 60px;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}

.button:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  font-size: 14px;
  font-family: inherit;
}

.small-button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 12px;
  padding: 7px 20px;
  border-radius: 25px;
}

.small-button:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}

/* Header */

.header-top-strip {
  border-bottom: 1px solid var(--color-3b4149);
  background-color: var(--color-131921);
}

.header-upper {
  background-color: var(--color-131921);
}

.header-bottom {
  background-color: var(--color-232f3e);
}

.header-upper .input-group-text {
  background-color: var(--color-febd69);
}

.header-upper .header-upper-links img {
  width: 35px;
  height: 35px;
}

.header-bottom .menu-links a,
.header-bottom .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}

.header-bottom .dropdown button:focus {
  box-shadow: none;
}

.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}

.header-bottom .dropdown-item {
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}

.header-bottom .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd69) !important;
}

/* Header Ends */

/* Footer */

footer {
  background-color: var(--color-232f3e);
}

footer:not(:first-child) {
  border-top: 1px solid var(--color-3b4149);
}

footer .input-group-text {
  background-color: var(--color-232f3e);
  color: white;
  padding: 8px 17px;
}

/* Footer Ends */

/* Product Card */

.product-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.product-card .product-image {
  height: 270px;
}

.product-card .product-image>img {
  object-fit: contain;
}

.product-card .product-image>img:last-child {
  display: none;
}

.product-card:hover .product-image>img:nth-child(1) {
  display: none;
}

.product-card:hover .product-image>img:last-child {
  display: block;
}

.product-card:hover .action-bar {
  right: 13px;
}

.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}

.product-card:hover .product-details p.price {
  color: white;
  font-size: 16px;
  background-size: 40% 100%;
}

.product-card .product-details h6,
.wishlist-card .brand {
  color: var(--color-bf4800);
  font-size: 13px;
}

.product-card .product-details h5 {
  color: var(--color-1c1c1b);
  font-size: 15px;
}

.product-card .product-details p.price{
  color: var(--color-1c1c1b);
  font-size: 16px;
  background-image: linear-gradient(var(--color-bf4800),var(--color-bf4800));
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: .4s;
}

.wishlist-card p.price,
.main-product-details p.price {
  color: var(--color-1c1c1b);
  font-size: 16px;
}

.detail-button {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 23%;
}

.action-bar {
  bottom: 15px;
  right: -100px;
  transition: 0.3s;
}

.wishlist-icon {
  top: 2%;
  right: 15px;

}


/* Product Card Ends */

/* Home Page */

.main-banner-content {
  top: 15%;
  left: 7%;
}

.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.main-banner-content h5 {
  font-size: 42px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -2px;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: var(--color-131921);
}

.small-banner-content {
  top: 20%;
  left: 10%;
}

.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.small-banner-content h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -2px;
  text-transform: none;
}

.small-banner-content p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: var(--color-131921);
}

.small-banner {
  width: 49%;
}

.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}

.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.categories>div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}

.categories>div>div {
  width: 60%;
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(4) {
  border-bottom: 1px solid #ededed;
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(5),
.categories>div:nth-child(6),
.categories>div:nth-child(7) {
  border-right: 1px solid #ededed;
}

.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

/* Home Page Ends */

/* Store Page */

.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px;
}

.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}

.filter-card ul,
.filter-card .form-check-label {
  font-size: 13px;
  list-style-type: none;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}

.filter-card .form-check-input:checked {
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);
}

.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: var(--color-131921);
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}

.filter-sort-grid {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.gr-12 {
  width: 100%;
}

.gr-6 {
  width: 49%;
}

.gr-4 {
  width: 32%;
}

.gr-3 {
  width: 48%;
}

/* Store Page Ends */

/* Contact Page */

.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}

.contact-inner-wrapper>div {
  width: 96%;
}

.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}

.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  margin-top: 0;
}

.contact-inner-wrapper ul {
  list-style-type: none;
}

.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}

/* Contact Page Ends */

/* Compare Page */

.compare-product-card {
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
}

.compare-product-card .cross,
.wishlist-card .cross {
  top: 10px;
  right: 10px;
  width: 25px;
  padding: 3px;
  cursor: pointer;
}

.compare-product-details h5.title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}

.compare-product-details h6.price {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}

.compare-product-card .product-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

/* Compare Page Ends */

/* Auth Card */

.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}

.auth-card h3{
  font-size: 24px;
  font-weight: 500;
  color: var(--color-777777);
}

.auth-card .form-control{
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}

.auth-card a{
  font-size: 13px;
  color: var(--color-777777);
}

.auth-card p{
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}

.signup{
  background-color: var(--color-febd69);
  color: var(--color-131921);
}

.signup:hover{
  background-color: var(--color-131921);
  color: white;
}

/* Auth Card Ends */

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}

/* Policy Pages Ends */

/* Product Pages */

.description-wrapper h4{
  font-size: 26px;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.description-wrapper p{
  font-size: 14px;
  color: var(--color-777777);
}

.main-product-image {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.main-product-image>div {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.main-product-image img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}

.other-product-images>div {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 20px;
  width: 48%;
}

.other-product-images {
  background-color: white;
  padding: 20px;
}

.main-product-details {
  padding: 30px 20px;
  border-radius: 10px;
  background-color: white;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.main-product-details h3.title{
  font-size: 18px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}

.product-data {
  font-size: 13px;
  color: var(--color-777777);
}

.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}

/* Product Pages Ends */

/* Cart Page */

.cart-header, .cart-data {
  border-bottom: 1px solid  #eaeaea;
}

.cart-header h4{
  font-size: 13px;
  color: var(--color-777777);
}

.cart-col-1{
  width: 40%;
}

.cart-col-1 p{
  color: var(--color-777777);
  font-size: 14px;
}

.cart-col-2{
  width: 10%;
}

.cart-col-3, .cart-col-4{
  width: 15%;
}

/* Cart Page Ends */

/* Chechkout Page */

.text-color-grey {
  color: var(--color-777777);
}

/* Chechkout Page Ends */